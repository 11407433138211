
.siscogImgContainer {
    margin-top: 1rem;
    position: relative;
    margin-bottom: 3rem;
}

.siscogImgContainer::before {
    content: '';
    position: absolute;
    bottom: 0; /* Positioned at the bottom */
    left: 0;
    width: 100%;
    height: 20%; /* Adjust as needed */
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    pointer-events: none; /* Allows clicks to pass through */
}

.dotContainer {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 2%; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    gap: 5px; /* Adjust as needed */
}

.dotStyles {
    color: rgba(255, 255, 255, 0.685);
    cursor: pointer;
    font-size: 2rem;
}

.active {
    color: #FF7F00;
}

@media screen and (max-width: 576px) {
    .dotStyles {
        font-size: 1.5rem;
    }
}