.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}

.sm-padding {
    padding: 0 2rem 0 0;
}

.skills__content {
    padding: 2rem 2rem;
}

.color-black {
    color: #1C1C1C;
}

.strong {
    font-weight: 500;
}

.uil {
    font-size: 16px;
}

.w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 16px;
    justify-content: center;
}

.double-layout-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.wireframes-layout-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.grid-user-insights {
    margin-top: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    font-size: 14px;
    line-height: 1.5em;
}

.grid-user-quotations {
    margin-top: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    font-size: 14px;
    line-height: 1.5em;
}

.grid-user-persona {
    margin-top: 30px;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    font-size: 14px;
    line-height: 1.5em;
}

.grid-ideation {
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    font-size: 14px;
    line-height: 1.5em;

}

.user-persona-info {
    background-color: rgb(242, 242, 242);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 3rem;
    border-radius: 1.25rem;
    align-items: center;
}

.user-persona-section {
    margin-top: 0.5rem;
}

.journey__content {
    margin-top: 1rem;
}

.mt-medium {
    margin-top: 1rem;
}

.user-persona-list {
    margin-top: .2rem;
    padding-left: 2rem;
    list-style-type: disc;
}

.persona-subtitle {
    margin-top: .2rem;
}

.insight-align-content {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
    color: black;
}

.insights-description {
    margin-top: 1rem;
}

.portfolio-hero-section {
    padding-top: 7.5rem;
}

.portfolio-section {
    padding: 3rem 2rem 0 2rem;
}

.portfolio-page-title {
    text-align: center;
}

.portfolio-page-subtitle {
    text-align: center;
}

.portfolio-description {
    margin-top: 1rem;
}

.portfolio-details {
    margin-right: auto;
    margin-left: auto;
}

.portfolio-tag.color-grey {
    color: #a8a8a8;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.portfolio-list {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.portfolio-list li {
    margin-bottom: 0.2rem;
}

.divider-25 {
    width: 25vw;
    height: 1px;
    margin-top: -30px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ececec;
}

.hoyBank__img {
    margin-top: 1rem;
}

.technologies_img {
    max-width: 400px;
    margin-top: 2rem;
}

.hoyBank__color {
    color: #22243b;
}

.title__color {
    color: #2B70F9 ;
}

.number__title {
    font-size: 18px;
    font-weight: 600;
    margin-right: 1rem;
    text-wrap: nowrap;
}

.horizontal-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
}

.hoyBank__bgcolor {
    background-color: #9df6ff1f;
}

.persona__img {
    border-radius: 1rem;
    align-items: center;
    max-height: 800px;
}

.usability {
    text-align: left;
}

.usability-paragraph {
    margin-top: 0.2rem;
    margin-bottom: 1rem;
}

/* container */
.responsive-two-column-grid {
    display:block;
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    padding: 0.5rem 0.5rem;
    font-size: 14px;
}

.responsive-two-column-grid:nth-child(even) {
    background-color: transparent;
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* columns */
.responsive-two-column-grid > * {
    padding:1rem;
}

/* tablet breakpoint */
@media (min-width:768px) {
    .responsive-two-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

/* For small devices */
@media screen and (max-width: 550px) {
    .w-layout-grid {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
    }

    .technologies_img {
        max-width: 300px;
    }

    .grid-user-persona {
        text-align: left;
    }

    .grid-user-insights {
        gap: 1.5rem;
    }

    .grid-user-quotations {
        grid-template-columns: 1fr !important;
        grid-column-gap: 1.5rem;
    }

}

/* For medium devices */
@media screen and (max-width: 768px) {
    .portfolio-hero-section {
        padding-top: 2rem;
    }
    .grid-user-quotations {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1.5rem;
    }

    .horizontal-grid {
        grid-template-columns: 1fr;
    }

    .grid-user-persona {
        padding: 1.5rem 3rem !important;
    }

    .grid-ideation {
        grid-template-columns: 1fr;
    }

    .grid-ideation .skills__content {
        padding: 1.5rem 1.5rem;
    }

    .wireframes-layout-grid {
        grid-template-columns: 1fr;
    }
}

/* For large devices */
@media screen and (max-width: 1000px) {
    .grid-user-persona {
        padding: 4rem 8rem;
    }
}

/* For large devices */
@media screen and (min-width: 1000px) {
    .grid-user-persona {
        grid-template-columns: 1fr 2fr;
    }
}
